import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import PostPreview from 'components/blog/PostPreview';
import './MorePosts.module.css';

function MorePosts({ intl, posts }) {
  if (posts.length === 0) {
    return null;
  }

  return (
    <div styleName="more-posts">
      <h4 styleName="title">
        {intl.formatMessage({ id: 'blog.related-articles' })}
      </h4>

      <div styleName="posts">
        {posts.map((post) => (
          <PostPreview post={post} key={post.uri} />
        ))}
      </div>
    </div>
  );
}

export default injectIntl(MorePosts);

MorePosts.propTypes = {
  intl: intlShape.isRequired,
  posts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};
