import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import { parseContent } from './PostCode';

import './Post.module.css';

function Post({ intl, data }) {
  const { title, content } = data.page;

  return (
    <div styleName="post">
      <h4 styleName="title">
        {title}
      </h4>

      <div styleName="content">
        <div>{parseContent(content)}</div>
      </div>
    </div>
  );
}

Post.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(Post);
