import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-intl';

import ArrowLeft from 'svg/arrow-left-solid.svg';
import './BackButton.module.css';

export default function BackButton({ label, to }) {
  return (
    <div styleName="back-button">
      <Link to={to}>
        <ArrowLeft />

        {label}
      </Link>
    </div>
  );
}

BackButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
