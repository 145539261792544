import React from 'react';
import PropTypes from 'prop-types';
import parse, { domToReact } from 'html-react-parser';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const LANG_PREFIX = 'lang-';

// The language for a code block is set via a class-name following the pattern:
// `lang-{LANGUAGE_TO_HIGHLIGHT}` e.g. `lang-toml` or `lang-python`.
const getLanguage = (node) => {
  const { attribs: { class: className = '' } } = node;

  const classes = className.split(' ');
  const langClass = classes.find((c) => c.startsWith(LANG_PREFIX));

  if (langClass) {
    // Remove the 'lang-' prefix with slice
    return langClass.slice(LANG_PREFIX.length);
  }

  // Default to 'bash' code highlighting
  return 'bash';
};

const getCode = (node) => {
  if (node.children.length > 0 && node.children[0].name === 'code') {
    return node.children[0].children;
  }

  if (node.children[0].type === 'tag') {
    return node.children[0].children;
  }

  return node.children;
};

function replaceCodeElements(node) {
  if (node.name !== 'pre' || node.children.length === 0) {
    return null;
  }

  // If the code element is already formatted (with hljs), just return that
  if (node.attribs.class === 'hljs') {
    return node;
  }

  return (
    <PostCode language={getLanguage(node)}>
      {domToReact(getCode(node))}
    </PostCode>
  );
}

export function parseContent(content) {
  return parse(content, { replace: replaceCodeElements });
}

export function PostCode({ language, children }) {
  // If 'children' is not already a string, assume it is a pre-formatted block of html.
  // These styles make the content have a dark code like appearance.
  if (typeof children !== 'string') {
    return (
      <pre
        style={{
          display: 'block',
          overflowX: 'auto',
          padding: '0.5em',
          background: 'rgb(43, 43, 43)',
          color: 'rgb(186, 186, 186)',
        }}
      >
        {children}
      </pre>
    );
  }

  return (
    <SyntaxHighlighter
      style={darcula}
      language={language}
    >
      {children}
    </SyntaxHighlighter>
  );
}

PostCode.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
