import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import './PostPreview.module.css';

/**
 * PostPreview is used on the blog archive page
 */
export default function PostPreview({ post, isHero }) {
  const data = useStaticQuery(graphql`
    query PostPreviewMeta {
      site {
        siteMetadata {
          blogPreferences {
            showAuthorPhoto
          }
        }
      }
    }
  `);
  const { site: { siteMetadata: { blogPreferences: { showAuthorPhoto } } } } = data;

  return (
    <div styleName="post" data-hero={isHero}>
      <Link to={`/blog${post.uri}`}>
        <div styleName="post-feature">
          {post.featuredImage ? (
            <Img
              fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
              styleName="post-image"
            />
          ) : <div style={{ minHeight: '225px' }} />}
          {/* forces this element to still take up 225px */}
        </div>

        <div styleName="post-info">
          <div>
            <time dateTime={post.date}>
              {post.date}
            </time>

            <h4>
              {post.title}
            </h4>

            {/* eslint-disable react/no-danger */}
            {isHero && post.excerpt && (
              <div styleName="excerpt" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            )}
            {/* eslint-enable react/no-danger */}
          </div>

          <div styleName="author">
            {showAuthorPhoto && (
              <Img
                fixed={post.author.node.avatar.imageFile.childImageSharp.fixed}
                objectFit="cover"
                objectPosition="50% 50%"
                styleName="author-image"
              />
            )}

            {post.author.node.name}
          </div>
        </div>
      </Link>
    </div>
  );
}

PostPreview.propTypes = {
  post: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isHero: PropTypes.bool,
};

PostPreview.defaultProps = {
  isHero: false,
};
