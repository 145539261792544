import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import { striptags } from 'striptags';

import Container from 'components/Container';
import Layout from 'components/Layout';
import BackButton from 'components/BackButton';
import MorePosts from 'components/blog/MorePosts';
import Post from 'components/blog/Post';

function getDescription(content) {
  return striptags(content);
}

const PostTemplate = ({ intl, data }) => {
  const {
    title,
    excerpt,
    dateGmt: publishedTime,
    modifiedGmt: updatedTime,
    author: { node: { name: author } },
    featuredImage: { node: { sourceUrl: imageUrl } },
  } = data.page;
  const description = getDescription(excerpt);

  return (
    <Layout>
      <Container>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />

          <meta property="og:site_name" content="Aotu.ai" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:updated_time" content={updatedTime} />
          <meta property="og:updated_time" content={updatedTime} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={imageUrl} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@aotuai" />

          <meta name="article:published_time" content={publishedTime} />
          <meta name="article:modified_time" content={updatedTime} />

          {author && author !== 'Aotu' && (
            <meta name="author" content={author} />
          )}

          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Article",
                "dateModified": "${updatedTime}",
                "datePublished": "${publishedTime}",
                "headline": "${title}",
                "description": "${description}",
                "image": [
                  "${imageUrl}"
                ]
              }
            `}
          </script>
        </Helmet>

        <BackButton to="/blog/" label={intl.formatMessage({ id: 'blog.back' })} />

        <Post data={data} />

        <MorePosts posts={data.posts.nodes} />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BackButton to="/blog/" label={intl.formatMessage({ id: 'blog.back' })} />
        </div>
      </Container>
    </Layout>
  );
};

PostTemplate.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(PostTemplate);

export const query = graphql`
  query post($id: String!) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
      dateGmt(formatString: "YYYY-MM-DD")
      modifiedGmt(formatString: "YYYY-MM-DD")
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
    posts: allWpPost(
      limit: 3
      sort: { fields: date, order: DESC }
      filter: {
        id: { ne: $id }
        categories: {
          nodes: { elemMatch: { slug: { ne: "newscard" } } }
        }
      }
    ) {
      nodes {
        ...PostPreviewContent
      }
    }
  }
`;
